import React, { Fragment } from "react";
import { Link } from "gatsby";

export default () => (
  <Fragment>
    <Link to="resume">An Pham</Link>'s Personal Blog
    <br />
    An enthusiastic software engineer who always strives for excellence. 😍
  </Fragment>
);
